import React, {useState} from 'react'
import { bankName } from './include'


const Footer = ()=>{
    
    return   <footer>
						<div id="copyright">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="site-info text-center">
										<p>The National Credit Union Administration provides insurance for this credit union. Unless otherwise noted, rates are current as of July 2024 and subject to change.

APR is the annual percentage rate; APY is the annual percentage yield.

"We thank all 6+ million members for choosing TOCU to save, borrow, and invest," says TOCU. "Our TOCU family continues to expand."
</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div id="google_translate_element"></div>
						</footer>
}
export default React.memo(Footer)