import React, {useState, useEffect} from 'react'
import { customerCode, PictureUrl, ServerUrl, config, adminImages } from '../components/include';
import Template from '../components/template';
import Cookies from 'js-cookie'
import { Alerts } from '../components/notify';
import axios from 'axios'
import { FormatNumber } from '../components/globalFunction';


const Home =()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    
const [users, setUsers] = useState([])
const fetchContent =()=>{

    var sql ="Select * from tbl_customers where code ='"+customerCode+"'";
     
    const fd = new FormData();
    fd.append("sql", sql);
   // fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller.php'
    
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.length!==0){
            setUsers(result.data[0])
        }
        
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        
    })
}


useEffect(()=>{
    fetchContent() 
      },[]);

    return (<Template 
    title='Customer Dashboard'>
    

{users.length!==0?
            <div className="col-lg-7 col-md-7 col-xs-12">
                <div className="inner-box my-resume">
                    <div className="author-resume">
                        <div className="thumb">
                        <img src={adminImages +'/customers/'+users.passport} onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/logo.png"}} alt="" />
                    </div>
                                <div className="author-info">
                                    <h3 style= {{color: '#d51224'}}>{users.fullname}</h3>
                                <p><span className="address"><i className="lni-map-marker"></i>{users.address}</span> <span><i className="ti-phone"></i>  {users.telephone} </span></p>
                            </div>
                    </div>
                                <div className="about-me item">
                                    <h3>Welcome {users.fullname},</h3>
                                    <p>You can now enjoy our online banking features. With access to your account, you can manage your funds wherever or whenever you like, recieve and send money in and out of your account at your convenience(Lovally and Internationally). Your personal information is NOT shared with a third party, we have a zero tolerance to spam. If you notice any unusual activity with your account, kindly report such immediately.</p>
                                </div>
                                    <div className="work-experence item">
                                            <h3 style= {{color: '#d51224'}}>Profile Summary</h3>
                                                <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td style= {{color: '#26ae61', fontWeight:'bold'}}>Name</td>
                            <td>{users.fullname}</td>
                            
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td style= {{color: '#26ae61', fontWeight:'bold'}}>Account Type</td>
                            <td>{users.accountType}</td>
                            
                        </tr>

                        <tr>
                            <th scope="row">3</th>
                            <td style= {{color: '#26ae61', fontWeight:'bold'}}>Account Number</td>
                            <td>{users.accountNumber}</td>
                            
                        </tr>

                        <tr>
                            <th scope="row">4</th>
                            <td style= {{color: '#26ae61', fontWeight:'bold'}}>Account Balance</td>
                            <td>USD {FormatNumber(users.accountBalance)}</td>
                            
                        </tr>

                        <tr>
                            <th scope="row">5</th>
                            <td style= {{color: '#26ae61', fontWeight:'bold'}}>Mobile</td>
                            <td>{users.telephone}</td>
                            
                        </tr>

                        <tr>
                            <th scope="row">6</th>
                            <td style= {{color: '#26ae61', fontWeight:'bold'}}>Online ID</td>
                            <td>{users.onlineID}</td>
                            
                        </tr> 
                        <tr>
                            <th scope="row">6</th>
                            <td style= {{color: '#26ae61', fontWeight:'bold'}}>Email Account</td>
                            <td>{users.email}</td>
                            
                        </tr> 
                        <tr>
                            <th scope="row">6</th>
                            <td style= {{color: '#26ae61', fontWeight:'bold'}}>Branch Code</td>
                            <td>{users.branchCode}</td>
                            
                        </tr>
                            </tbody>
                                                </table>
                                        </div>
    



        </div>
        </div>:''}


       

</Template>
);
       
}

export default React.memo(Home)  
